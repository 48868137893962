import Aos from "aos";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Home() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <div className="hero-section pb-[94px]">
        <div className="relative overflow-hidden max-w-[514px] w-full mx-auto">
        <img src="/assets/item8.svg" className="absolute z-10 top-[61px] left-[91px]" alt="" />
        <img src="/assets/item8.svg" className="absolute z-10 bottom-[42px] right-[-21px]" alt="" />
          <h1
            data-aos="fade-up"
            style={{ fontFamily: "HoneyBear" }}
            className="sm:text-[80px] text-[40px] pb-[58px] pt-[22px] text-[#FFFFFF] text-center uppercase m-0 font-[400] "
          >
            dark dog
          </h1>
        </div>
        <div className="flex justify-center flex-col items-center">
          <img src="assets/logo.svg" data-aos="fade-right" alt="" />
          <h6
            data-aos="fade-up"
            style={{ fontFamily: "Komit" }}
            className="sm:text-[56px] text-[34px] text-[#FFFFFF] max-w-[659px] sm:pb-[98px] pb-[30px] m-auto w-full sm:leading-[60px] leading-[42px] !font-[400] uppercase text-center"
          >
            Dark Dog has arrived, joining the movement to pump your portfolio!
          </h6>
          <button>
            <img src="/assets/item2.svg" className="sm:h-auto h-[50px] object-cover" alt="" />
          </button>
        </div>
      </div>
      <div className="section-2 pt-[112px]">
        <h1
          data-aos="fade-right"
          style={{ fontFamily: "Komit" }}
          className="text-[56px] pb-[33px] text-center uppercase m-0 text-[#FFFFFF]"
        >
          HOW TO BUY
        </h1>
        <h6
          data-aos="fade-left"
          style={{ fontFamily: "Komit" }}
          className="text-[36px] text-[#FFFFFF] max-w-[538px] w-full m-auto pb-[96px] uppercase  text-center"
        >
          Follow these easy steps to become a darkdog holder
        </h6>
        <div className="max-w-[1500px] m-auto px-[30px] w-full">
          <div className="grid sm:grid-cols-4 grid-cols-1 gap-[20px] pb-[100px]">
            <div className="dog-card overflow-hidden">
              <h6
                data-aos="fade-right"
                style={{ fontFamily: "HoneyBear" }}
                className="text-[16px] uppercase m-0 font-[400] text-[#FFFFFF] pb-[16px] leading-[30px]"
              >
                Choose a Cryptocurrency Exchange:
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Komit" }}
                className="uppercase text-[24px] leading-[28px] text-[#FFFFFF]"
              >
                Select a reputable exchange that supports Solana (SOL) trading
              </p>
            </div>
            <div className="dog-card overflow-hidden">
              <h6
                data-aos="fade-right"
                style={{ fontFamily: "HoneyBear" }}
                className="text-[16px] uppercase m-0 font-[400] text-[#FFFFFF] pb-[16px] leading-[30px]"
              >
                Create an Account:
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Komit" }}
                className="uppercase text-[24px] leading-[28px] text-[#FFFFFF]"
              >
                Sign up on the chosen exchange platform. Provide necessary
                identification and complete any verification processes required.
              </p>
            </div>
            <div className="dog-card overflow-hidden">
              <h6
                data-aos="fade-right"
                style={{ fontFamily: "HoneyBear" }}
                className="text-[16px] uppercase m-0 font-[400] text-[#FFFFFF] pb-[16px] leading-[30px]"
              >
                Deposit Funds:
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Komit" }}
                className="uppercase text-[24px] leading-[28px] text-[#FFFFFF]"
              >
                Deposit funds into your exchange account using methods like bank
                transfer, credit card, or cryptocurrency transfer.
              </p>
            </div>
            <div className="dog-card overflow-hidden">
              <h6
                data-aos="fade-right"
                style={{ fontFamily: "HoneyBear" }}
                className="text-[16px] uppercase m-0 font-[400] text-[#FFFFFF] pb-[16px] leading-[30px]"
              >
                Buy Solana (SOL):
              </h6>
              <p
                data-aos="fade-left"
                style={{ fontFamily: "Komit" }}
                className="uppercase text-[24px] leading-[28px] text-[#FFFFFF]"
              >
                SNavigate to the trading section of the exchange, search for
                Solana (SOL), and execute your purchase. You can buy SOL
                directly using fiat currency or exchange other cryptocurrencies
                for SOL.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="line-loop-container">
        <div className="line-loop flex items-center gap-[50px]">
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
          <h6
            className="font-[400] text-[56px] w-full leading-[60px] text-[#FFFFFF]"
            style={{ fontFamily: "Komit" }}
          >
            $ddog
          </h6>
        </div>
      </div>
      <div className="bg-[#170300] overflow-hidden">
        <h1
          data-aos="fade-down"
          style={{ fontFamily: "Komit" }}
          className="text-[64px] pt-[141px] text-center uppercase m-0 text-[#FF5D5D] pb-[6px]"
        >
          SOCIALS
        </h1>
        <h6
          data-aos="fade-up"
          style={{ fontFamily: "Komit" }}
          className="text-[36px] text-[#FF5D5D] max-w-[800px] w-full m-auto pb-[80px] uppercase text-center"
        >
          Join the $darkdog community
        </h6>
        <ul className="flex justify-center items-center gap-[40px]">
          <Link
            className="h-[100px] rotate-[20.77deg] w-[100px]"
            target="_blank"
            to="https://x.com/ddogonsolana"
            rel="noopener noreferrer"
          >
            <img
              src="assets/item5.svg"
              alt=""
              className="h-[100px] w-[100px]"
            />
          </Link>
          <Link
            className="h-[100px] rotate-[-8.3deg] w-[100px]"
            target="_blank"
            to="https://t.me/ddogportal"
            rel="noopener noreferrer"
          >
            <img
              src="assets/item6.svg"
              alt=""
              className="h-[100px] w-[100px]"
            />
          </Link>
          {/* <Link
            className="h-[100px] rotate-[16.58deg] w-[100px]"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="assets/item3.svg"
              alt=""
              className="h-[100px] p-[14px] w-[100px] bg-[#000000]"
            />
          </Link> */}
        </ul>
        <div data-aos="fade-up" className="flex justify-center pt-[78px]">
          <img src="assets/item4.svg" alt="" />
        </div>
      </div>
    </>
  );
}

export default Home;
